

.nav-link{
    color:white
}
.nav-link:focus{
    color: var(--bs-warning);
}
.isactive{
    color: var(--bs-warning);
    font-weight: bolder;
    
}



@media (max-width: 768px) { 
    .nav-link{
        color:black
    }
    .nav-link:focus{
        color: var(--bs-primary);
    }
    .isactive{
        color: var(--bs-primary);
        font-weight: bolder;
        
    }
 }