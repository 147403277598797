

/* Change all display font family */
*[class*='display-']{
    font-family: 'Abril Fatface', cursive; 
}

.h1, .h2, .h3, .h4, .h5, .h6{
font-family: 'Oswald', sans-serif;
    
}



.text-shadow-none{
    text-shadow: 0px 0px 0px;
}


.text-shadow-black{
    text-shadow: 2px 2px 2px black;
}

.text-shadow-grey{
    text-shadow: 1px 1px 2px grey;
}
.text-shadow-white{
    text-shadow: 1px 1px 2px white;
}

/* .df1{
    font-family: 'Abril Fatface', cursive;
} */



.olddf2{
font-family: 'Newsreader', serif;
/* white-space: nowrap; */
}

.oldhf1{
font-family: 'Bebas Neue', cursive;
}


.oldtitle{
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 1px;
    font-weight: bold;
}

.oldff1{
    font-family: 'Noto Serif Hebrew', serif;
    font-family: 'Alumni Sans', sans-serif;
    /* font-size: smaller; */
    line-height: 110%; 
    /* font-family: 'Poppins', sans-serif; */
    /* all weights are available */
    }

.oldff2{
    font-family: 'Roboto', sans-serif;
    /* all weights are available */
    }

.text-spacing-1{
    letter-spacing: 1px;

}

.fw100{
    font-weight: 100;
}
.fw200{
    font-weight: 200;
}

.fw300{
    font-weight: 300;
}
.fw400{
    font-weight: 400;
}
.fw500{
    font-weight: 500;
}
.fw600{
    font-weight: 600;
}
.fw700{
    font-weight: 700;
}
.fw800{
    font-weight: 800;
}
.fw900{
    font-weight: 900;
}